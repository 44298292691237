import { APICore } from './apiCore';

const api = new APICore();

function absence() {
    const baseUrl = '/api/absence/';
    return api.get(`${baseUrl}`, undefined);
}

function absenceTypes() {
    const baseUrl = '/api/absence/Types';
    return api.get(`${baseUrl}`, undefined);
}

export { absence, absenceTypes };
