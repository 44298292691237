import Routes from 'routes/Routes';
import './assets/scss/Saas.scss';
import * as Sentry from "@sentry/react";
//import { configureFakeBackend } from 'helpers';

const App = () => {
  Sentry.init({
    dsn: "https://dcc36721162536b6d84e0fcac52bd825@o1054694.ingest.us.sentry.io/4507299542532096",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  
  //configureFakeBackend();
  return <Routes />;
};
export default App;
