import {put, call, all, fork, takeEvery} from 'redux-saga/effects';
import {
    getAllEmployees as getAllEmployeesApi,
} from '../../helpers';
import {SagaIterator} from "@redux-saga/core";
import {employeeListApiResponseError, employeeListApiResponseSuccess} from "./actions";
import {EmployeeListActionTypes} from "./constants";

function* data(): SagaIterator {
    try {
        const response = yield call(getAllEmployeesApi);
        const user = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(employeeListApiResponseSuccess(EmployeeListActionTypes.API_RESPONSE_SUCCESS, user));
    } catch (error: any) {
        yield put(employeeListApiResponseError(EmployeeListActionTypes.API_RESPONSE_ERROR, error));
    }
}

export function* watch() {
    yield takeEvery(EmployeeListActionTypes.DATA, data);
}

function* employeeListSaga() {
    yield all([fork(watch)]);
}

export default employeeListSaga;