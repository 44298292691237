import { APICore } from './apiCore';
import {IEmployee} from "../../models/types/types";
import {AxiosResponse} from "axios";

const api = new APICore();

function getAll(): Promise<AxiosResponse<IEmployee[]>> {
    const baseUrl = '/api/employee/';
    return api.get(`${baseUrl}`, undefined);
}

export { getAll };
