import {put, call, all, fork, takeEvery} from 'redux-saga/effects';
import {
    absence as absenceApi,
} from '../../helpers';
import {SagaIterator} from "@redux-saga/core";
import {absenceApiResponseError, absenceApiResponseSuccess} from "./actions";
import {AbsenceActionTypes} from "./constants";

function* data(): SagaIterator {
    try {
        const response = yield call(absenceApi);
        const user = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(absenceApiResponseSuccess(AbsenceActionTypes.API_RESPONSE_SUCCESS, user));
    } catch (error: any) {
        yield put(absenceApiResponseError(AbsenceActionTypes.API_RESPONSE_ERROR, error));
    }
}

export function* watchAbsence() {
    yield takeEvery(AbsenceActionTypes.DATA, data);
}

function* absenceSaga() {
    yield all([fork(watchAbsence)]);
}

export default absenceSaga;