import { APICore } from './apiCore';
import {ILoginRequest} from "../../models/types/types";

const api = new APICore();

function profile() {
    const baseUrl = '/api/capacity/';
    return api.get(`${baseUrl}`, undefined);
}

export { profile };
