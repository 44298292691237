import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import profileSaga from "./capacity/saga";
import absenceSaga from "./absence/saga";
import employeeListSaga from "./Employees/saga";

export default function* rootSaga() {
    yield all([authSaga(), layoutSaga(), profileSaga(), absenceSaga(), employeeListSaga()]);
}
