import {put, call, all, fork, takeEvery} from 'redux-saga/effects';
import {
    profile as profileApi,
} from '../../helpers';
import {SagaIterator} from "@redux-saga/core";
import {profileApiResponseError, profileApiResponseSuccess} from "./actions";
import {ProfileActionTypes} from "./constants";

function* profile(): SagaIterator {
    try {
        const response = yield call(profileApi);
        const user = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(profileApiResponseSuccess(ProfileActionTypes.API_RESPONSE_SUCCESS, user));
    } catch (error: any) {
        yield put(profileApiResponseError(ProfileActionTypes.API_RESPONSE_ERROR, error));
    }
}

export function* watchProfile() {
    yield takeEvery(ProfileActionTypes.PROFILE, profile);
}

function* profileSaga() {
    yield all([fork(watchProfile)]);
}

export default profileSaga;