import React, {Suspense} from 'react';
import {useRoutes} from 'react-router-dom';
import {DefaultLayout, VerticalLayout, HorizontalLayout, DetachedLayout, FullLayout} from 'layouts';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import {LayoutTypes} from 'appConstants';
import {useRedux} from 'hooks';
// lazy load all the views
// auth
const Login = React.lazy(() => import('pages/account/Login'));
const Logout = React.lazy(() => import('pages/account/Logout'));
const Register = React.lazy(() => import('pages/account/Register'));
const Confirm = React.lazy(() => import('pages/account/Confirm'));

// ressource
const EmployeeList = React.lazy(() => import('pages/ressource/Employee'));

//registration
const TimeEntryRegistration = React.lazy(() => import('pages/registration/Timeentry'));
const AbsenceRegistration = React.lazy(() => import('pages/registration/AbsenceEntry'));
//Dashboard
const OverallDashboard = React.lazy(() => import('pages/dashboard/Overall'));
const EmployeeDashboard = React.lazy(() => import('pages/dashboard/Employee'));

//Settings
const TimeEntrySetting = React.lazy(() => import('pages/settings/Timeentry'));
const AbsenceSetting = React.lazy(() => import('pages/settings/AbsenceEntry'));
const loading = () => <div className=""></div>;


type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({component: Component}: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component/>
    </Suspense>
);

const AllRoutes = () => {
    const {appSelector} = useRedux();

    const {layout} = appSelector((state) => ({
        layout: state.Layout,
    }));

    const getLayout = () => {
        let layoutCls: React.ComponentType = VerticalLayout;

        switch (layout.layoutType) {
            case LayoutTypes.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case LayoutTypes.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case LayoutTypes.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        {path: '/', element: <Root/>},
        {
            // public routes
            path: '/',
            element: <DefaultLayout/>,
            children: [
                {
                    path: 'account',
                    children: [
                        {path: 'login', element: <LoadComponent component={Login}/>},
                        {path: 'register', element: <LoadComponent component={Register}/>},
                        {path: 'confirm', element: <LoadComponent component={Confirm}/>},
                        // { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                        // { path: 'lock-screen', element: <LoadComponent component={LockScreen} /> },
                        {path: 'logout', element: <LoadComponent component={Logout}/>},
                        // { path: 'login2', element: <LoadComponent component={Login2} /> },
                        // { path: 'register2', element: <LoadComponent component={Register2} /> },
                        // { path: 'confirm2', element: <LoadComponent component={Confirm2} /> },
                        // { path: 'forget-password2', element: <LoadComponent component={ForgetPassword2} /> },
                        // { path: 'lock-screen2', element: <LoadComponent component={LockScreen2} /> },
                        // { path: 'logout2', element: <LoadComponent component={Logout2} /> },
                    ],
                },
            ],
        },
        {
            path: '/',
            element: <PrivateRoute roles={['Admin', 'User']} component={Layout}/>,
            children: [{
                path: 'dashboard',
                children:
                    [
                        {
                            path: 'overall',
                            element: <LoadComponent component={OverallDashboard}/>
                        },
                        {
                            path: 'employee',
                            element: <LoadComponent component={EmployeeDashboard}/>
                        }
                    ]
            },
                {
                    path: 'registration',
                    children:
                        [
                            {
                                path: 'timeentry',
                                element: <LoadComponent component={TimeEntryRegistration}/>
                            },
                            {
                                path: 'absence',
                                element: <LoadComponent component={AbsenceRegistration}/>
                            },
                        ]
                },
                {
                    path: 'ressource',
                    children:
                        [
                            {
                                path: 'employee',
                                element: <LoadComponent component={EmployeeList}/>
                            }
                        ]
                },
                {
                    path: 'recruitment',
                    children: [
                        {
                            path: 'candidates',
                        }
                    ]
                }
            ]
        },
        {
            path: '/',
            element: <PrivateRoute roles={'Admin'} component={Layout}/>,
            children: [{
                path: 'setting',
                children:
                    [
                        {
                            path: 'timeentry',
                            element: <LoadComponent component={TimeEntrySetting}/>
                        },
                        {
                            path: 'absence',
                            element: <LoadComponent component={AbsenceSetting}/>
                        },
                    ]
            }]
        }
    ]);
};

export {AllRoutes};
