import {AbsenceActionTypes} from "./constants";

export type AbsenceActionType = {
    type:
        | AbsenceActionTypes.DATA
        | AbsenceActionTypes.API_RESPONSE_SUCCESS
        | AbsenceActionTypes.API_RESPONSE_ERROR;
    payload: {} | string;
}

// common success
export const absenceApiResponseSuccess = (actionType: string, data: any): AbsenceActionType => ({
    type: AbsenceActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const absenceApiResponseError = (actionType: string, error: string): AbsenceActionType => ({
    type: AbsenceActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getAbsences = (): AbsenceActionType => ({
    type: AbsenceActionTypes.DATA,
    payload: { },
});