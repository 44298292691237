import {ProfileActionTypes} from "./constants";

export type ProfileActionType = {
    type:
        | ProfileActionTypes.PROFILE
        | ProfileActionTypes.API_RESPONSE_SUCCESS
        | ProfileActionTypes.API_RESPONSE_ERROR;
    payload: {} | string;
}

// common success
export const profileApiResponseSuccess = (actionType: string, data: any): ProfileActionType => ({
    type: ProfileActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const profileApiResponseError = (actionType: string, error: string): ProfileActionType => ({
    type: ProfileActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getProfile = (): ProfileActionType => ({
    type: ProfileActionTypes.PROFILE,
    payload: { },
});