import {EmployeeListActionTypes} from "./constants";

export type EmployeeListActionType = {
    type:
        | EmployeeListActionTypes.DATA
        | EmployeeListActionTypes.API_RESPONSE_SUCCESS
        | EmployeeListActionTypes.API_RESPONSE_ERROR;
    payload: {} | string;
}

// common success
export const employeeListApiResponseSuccess = (actionType: string, data: any): EmployeeListActionType => ({
    type: EmployeeListActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const employeeListApiResponseError = (actionType: string, error: string): EmployeeListActionType => ({
    type: EmployeeListActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getEmployeeList = (): EmployeeListActionType => ({
    type: EmployeeListActionTypes.DATA,
    payload: { },
});