import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import Layout from './layout/reducers';
import Profile from "./capacity/reducers";
import Absence from "./absence/reducers";
import EmployeeList from "./Employees/reducers";

export default combineReducers({
    Auth,
    Profile,
    Absence,
    Layout,
    EmployeeList
});
