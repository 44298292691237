import { APICore } from '../../helpers/api/apiCore';
import { EmployeeListActionTypes } from './constants';
import { IEmployee} from "../../models/types/types";

const api = new APICore();

const INIT_STATE = {
    data: [],
    loading: false,
};
type EmployeeListActionType = {
    type:
        | EmployeeListActionTypes.DATA
        | EmployeeListActionTypes.API_RESPONSE_SUCCESS
        | EmployeeListActionTypes.API_RESPONSE_ERROR;
    payload: {
        data: []
    };
}

type State = {
    data?: IEmployee[] | [];
    loading?: boolean;
    value?: boolean;
};

const EmployeeList = (state: State = INIT_STATE, action: EmployeeListActionType) => {
    switch (action.type) {
        case EmployeeListActionTypes.API_RESPONSE_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                loading: false,
            };
        default:
            return { ...state };
    }
};

export default EmployeeList;
